@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.type {
  @include font-style-tiny($font-weight-bold);
  color: var(--text-20);
  text-transform: uppercase;

  margin: 0;
  padding-top: 12px;
}

.button {
  text-align: left;
  border: none;
  background-color: transparent;
}

.cardWrapper {
  position: relative;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}

.cardWrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--shape-50);
}

.cardWrapperActive::before {
  box-shadow: inset 0 0 0 2px var(--system-success);
}

.cardWrapperActive .icon {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: var(--system-success);
}

.systemCard {
  position: relative;
  display: flex;
  width: 124px;
  height: 92px;

  & .card {
    width: 50%;
    height: 100%;

    border: none;
    border-radius: 0;
  }

  & .content {
    width: 36px;
  }
}
